// import React from "react";
// import ReactDOM from "react-dom";
// import i18n from './i18n';
// import App from "./app/App";
// import { store } from "./store";
// import { Provider, Router } from "./ui-lib";
// import history from './ui-lib/history';

import { UI_VERSION } from './ui-lib/version'

// import { getWebSecurityPromise } from './onAuthPromises'
// ReactDOM.render(
// 	<Provider store={store}>
// 		<Router history={history}>
// 			<App />
// 		</Router>
// 	</Provider>,
// 	document.getElementById("fawkes-root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

const manage_app = ({ sparkyFramework: { onAuthed, StartupManager, fawkesUtils: { getRedirectURL, getAuthHeaders}, getAuthState, hasTsgSupport} }) => {
    onAuthed(({
        getFeatures,
        getLicenseInfo,
        getFqdnInfo,
        getAuthInfo,
        getSubTenantInfo,
        getMigrationStatus,
        getNotifications,
        setMainValues,
        getMainState,
        setValues,
        waitExtraPromises
    }) => {
        getFeatures()
        getLicenseInfo()
        getFqdnInfo()
        getAuthInfo()
        getMigrationStatus()
        getNotifications()
        const state = getMainState()
        const type = state.tenant.type
        const getWebSecurityPromise = () => {
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 10000)
			return fetch(getRedirectURL() + `api/config/v9.2/enable`, {
                signal: controller.signal,
				withCredentials: true,
				method: 'GET',
				responseType: 'json',
				headers: getAuthHeaders(),
			})
				.then(async (response) => {
					let resp = await response.json();
					const isEnable = resp?.result?.enable;
					if (isEnable === 'yes') setValues({ webSecurityAvail: true });
                    clearTimeout(timeoutId);
				})
				.catch((err) => {
					console.log(err);
				});
		};
        if (StartupManager.getConfigManagedType() === StartupManager.PANORAMA_CONFIG) {
            getSubTenantInfo()
        }
		if(type!==StartupManager.PANORAMA_CONFIG)
        waitExtraPromises([getWebSecurityPromise()]);
        //checking if sparky info is loaded and setting configLocation if not available
        //upon landing on SaaS security screen, configLocation is not yet set.
        let intervalRuns = 0;
        let interval = setInterval(()=>{
            const mainState = getMainState() || {};
            const { infoLoaded , configLocation = {} } = mainState;
            if(infoLoaded && !configLocation?.container){
                setMainValues({
                    configLocation: { container: { name: 'Prisma Access', type: 'container', locationPath: 'Prisma Access' } },
                });
                clearInterval(interval)
            }else{
                intervalRuns++;
                if(intervalRuns > 10) clearInterval(interval);
            }
        },500)
        
    })


     //local version set during deploy time
     let localVersion = UI_VERSION;
     let fn =  () => {
         return Promise.all([
            import(/* webpackPreload: true, webpackChunkName: "predef" */ './predefined'),
            import(/* webpackPreload: true */ './i18n'),
        ]).then(() => import(/* webpackPreload: true */ './routes')).then(r => r.default)
     }
     console.log('Multi-site-fawkes hasTsgSupport', hasTsgSupport())
     //Need to handle MSP case
     if (hasTsgSupport()) {
        console.log('Multi-site-fawkes has tsg', hasTsgSupport())
         return () => {
             //detect the version 
             let version = getAuthState()?.instances?.get('prisma_access')?.runtime_attributes?.version;
             console.log('Multi-site-fawkes has version', localVersion, version)
             if (localVersion == version || !version) {
                 //varsion match Load this site
                 //no version is a fall back. Load FAWKES
                 return fn();
             } else {
                 //return null
                 return null;
             }
         }
     } else {
        console.log('Multi-site-fawkes no tsg')
         //Non TSG or MSP case
         return fn();
     }
}
manage_app.exposes = {
    responsePage: () => import('./exposedFunctions.js'),
    logViewerDependency: () => import('./exposedFunctionsLogViewer.js'),
}
export default manage_app;
